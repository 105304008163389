const skillsSvgData = { 'front': { 'stroke-length': 735}, 'back': { 'stroke-length': 584 }, 'tools': { 'stroke-length': 587 }}

const patterns = { 'diam' : {'url' : 'https://robinvitre.s3.eu-west-3.amazonaws.com/diam.svg'},
'kube' : {'url' : 'https://robinvitre.s3.eu-west-3.amazonaws.com/kube.svg'},
'dode' : {'url' : 'https://robinvitre.s3.eu-west-3.amazonaws.com/dode.svg'}
}

const headerGrad = `
background : -moz-linear-gradient(27.24% 117.6% 65deg,rgba(56, 22, 184, 0.3) 2.61%,rgba(84, 26, 172, 0.36) 7.57%,rgba(108, 29, 161, 0.43) 13%,rgba(123, 30, 155, 0.5) 18.04%,rgba(128, 31, 153, 0.55) 22.25%,rgba(119, 40, 156, 0.6) 26.76%,rgba(94, 63, 166, 0.75) 41.12%,rgba(79, 77, 171, 0.89) 54.29%,rgba(74, 82, 173, 1) 65.09%,rgba(74, 87, 176, 0.96) 70.23%,rgba(72, 102, 185, 0.92) 76.54%,rgba(71, 127, 201, 0.87) 83.46%,rgba(68, 162, 222, 0.82) 90.82%,rgba(65, 206, 249, 0.76) 98.45%,rgba(64, 216, 255, 0.75) 100%);
background : -webkit-linear-gradient(65deg, rgba(56, 22, 184, 0.3) 2.61%, rgba(84, 26, 172, 0.36) 7.57%, rgba(108, 29, 161, 0.43) 13%, rgba(123, 30, 155, 0.5) 18.04%, rgba(128, 31, 153, 0.55) 22.25%, rgba(119, 40, 156, 0.6) 26.76%, rgba(94, 63, 166, 0.75) 41.12%, rgba(79, 77, 171, 0.89) 54.29%, rgba(74, 82, 173, 1) 65.09%, rgba(74, 87, 176, 0.96) 70.23%, rgba(72, 102, 185, 0.92) 76.54%, rgba(71, 127, 201, 0.87) 83.46%, rgba(68, 162, 222, 0.82) 90.82%, rgba(65, 206, 249, 0.76) 98.45%, rgba(64, 216, 255, 0.75) 100%);
background : -webkit-gradient(linear,27.24% 117.6% ,72.76% -17.6% ,color-stop(0.0261,rgba(56, 22, 184, 0.3) ),color-stop(0.0757,rgba(84, 26, 172, 0.36) ),color-stop(0.13,rgba(108, 29, 161, 0.43) ),color-stop(0.1804,rgba(123, 30, 155, 0.5) ),color-stop(0.2225,rgba(128, 31, 153, 0.55) ),color-stop(0.2676,rgba(119, 40, 156, 0.6) ),color-stop(0.4112,rgba(94, 63, 166, 0.75) ),color-stop(0.5429,rgba(79, 77, 171, 0.89) ),color-stop(0.6509,rgba(74, 82, 173, 1) ),color-stop(0.7023,rgba(74, 87, 176, 0.96) ),color-stop(0.7654,rgba(72, 102, 185, 0.92) ),color-stop(0.8346,rgba(71, 127, 201, 0.87) ),color-stop(0.9082,rgba(68, 162, 222, 0.82) ),color-stop(0.9845,rgba(65, 206, 249, 0.76) ),color-stop(1,rgba(64, 216, 255, 0.75) ));
background : -o-linear-gradient(65deg, rgba(56, 22, 184, 0.3) 2.61%, rgba(84, 26, 172, 0.36) 7.57%, rgba(108, 29, 161, 0.43) 13%, rgba(123, 30, 155, 0.5) 18.04%, rgba(128, 31, 153, 0.55) 22.25%, rgba(119, 40, 156, 0.6) 26.76%, rgba(94, 63, 166, 0.75) 41.12%, rgba(79, 77, 171, 0.89) 54.29%, rgba(74, 82, 173, 1) 65.09%, rgba(74, 87, 176, 0.96) 70.23%, rgba(72, 102, 185, 0.92) 76.54%, rgba(71, 127, 201, 0.87) 83.46%, rgba(68, 162, 222, 0.82) 90.82%, rgba(65, 206, 249, 0.76) 98.45%, rgba(64, 216, 255, 0.75) 100%);
background : -ms-linear-gradient(65deg, rgba(56, 22, 184, 0.3) 2.61%, rgba(84, 26, 172, 0.36) 7.57%, rgba(108, 29, 161, 0.43) 13%, rgba(123, 30, 155, 0.5) 18.04%, rgba(128, 31, 153, 0.55) 22.25%, rgba(119, 40, 156, 0.6) 26.76%, rgba(94, 63, 166, 0.75) 41.12%, rgba(79, 77, 171, 0.89) 54.29%, rgba(74, 82, 173, 1) 65.09%, rgba(74, 87, 176, 0.96) 70.23%, rgba(72, 102, 185, 0.92) 76.54%, rgba(71, 127, 201, 0.87) 83.46%, rgba(68, 162, 222, 0.82) 90.82%, rgba(65, 206, 249, 0.76) 98.45%, rgba(64, 216, 255, 0.75) 100%);
background : linear-gradient(25deg, rgba(56, 22, 184, 0.3) 2.61%, rgba(84, 26, 172, 0.36) 7.57%, rgba(108, 29, 161, 0.43) 13%, rgba(123, 30, 155, 0.5) 18.04%, rgba(128, 31, 153, 0.55) 22.25%, rgba(119, 40, 156, 0.6) 26.76%, rgba(94, 63, 166, 0.75) 41.12%, rgba(79, 77, 171, 0.89) 54.29%, rgba(74, 82, 173, 1) 65.09%, rgba(74, 87, 176, 0.96) 70.23%, rgba(72, 102, 185, 0.92) 76.54%, rgba(71, 127, 201, 0.87) 83.46%, rgba(68, 162, 222, 0.82) 90.82%, rgba(65, 206, 249, 0.76) 98.45%, rgba(64, 216, 255, 0.75) 100%);
`;

const skillsGrad = `
background : -moz-linear-gradient(2.41% 100.56% 65deg,rgba(132, 70, 163, 0.7) 0%,rgba(128, 68, 160, 0.71) 0.69%,rgba(96, 53, 136, 0.77) 6.34%,rgba(77, 43, 122, 0.82) 11.11%,rgba(70, 40, 117, 0.85) 14.4%,rgba(55, 40, 117, 1) 47.71%,rgba(56, 42, 118, 0.85) 66.84%,rgba(57, 50, 120, 0.78) 76.51%,rgba(61, 63, 123, 0.72) 84.06%,rgba(65, 82, 128, 0.67) 90.52%,rgba(71, 106, 134, 0.63) 96.21%,rgba(76, 127, 140, 0.6) 100%);
background : -webkit-linear-gradient(65deg, rgba(132, 70, 163, 0.7) 0%, rgba(128, 68, 160, 0.71) 0.69%, rgba(96, 53, 136, 0.77) 6.34%, rgba(77, 43, 122, 0.82) 11.11%, rgba(70, 40, 117, 0.85) 14.4%, rgba(55, 40, 117, 1) 47.71%, rgba(56, 42, 118, 0.85) 66.84%, rgba(57, 50, 120, 0.78) 76.51%, rgba(61, 63, 123, 0.72) 84.06%, rgba(65, 82, 128, 0.67) 90.52%, rgba(71, 106, 134, 0.63) 96.21%, rgba(76, 127, 140, 0.6) 100%);
background : -webkit-gradient(linear,2.41% 100.56% ,97.59% -0.56% ,color-stop(0,rgba(132, 70, 163, 0.7) ),color-stop(0.0069,rgba(128, 68, 160, 0.71) ),color-stop(0.0634,rgba(96, 53, 136, 0.77) ),color-stop(0.1111,rgba(77, 43, 122, 0.82) ),color-stop(0.144,rgba(70, 40, 117, 0.85) ),color-stop(0.4771,rgba(55, 40, 117, 1) ),color-stop(0.6684,rgba(56, 42, 118, 0.85) ),color-stop(0.7651,rgba(57, 50, 120, 0.78) ),color-stop(0.8406,rgba(61, 63, 123, 0.72) ),color-stop(0.9052,rgba(65, 82, 128, 0.67) ),color-stop(0.9621,rgba(71, 106, 134, 0.63) ),color-stop(1,rgba(76, 127, 140, 0.6) ));
background : -o-linear-gradient(65deg, rgba(132, 70, 163, 0.7) 0%, rgba(128, 68, 160, 0.71) 0.69%, rgba(96, 53, 136, 0.77) 6.34%, rgba(77, 43, 122, 0.82) 11.11%, rgba(70, 40, 117, 0.85) 14.4%, rgba(55, 40, 117, 1) 47.71%, rgba(56, 42, 118, 0.85) 66.84%, rgba(57, 50, 120, 0.78) 76.51%, rgba(61, 63, 123, 0.72) 84.06%, rgba(65, 82, 128, 0.67) 90.52%, rgba(71, 106, 134, 0.63) 96.21%, rgba(76, 127, 140, 0.6) 100%);
background : linear-gradient(25deg, rgba(132, 70, 163, 0.7) 0%, rgba(128, 68, 160, 0.71) 0.69%, rgba(96, 53, 136, 0.77) 6.34%, rgba(77, 43, 122, 0.82) 11.11%, rgba(70, 40, 117, 0.85) 14.4%, rgba(55, 40, 117, 1) 47.71%, rgba(56, 42, 118, 0.85) 66.84%, rgba(57, 50, 120, 0.78) 76.51%, rgba(61, 63, 123, 0.72) 84.06%, rgba(65, 82, 128, 0.67) 90.52%, rgba(71, 106, 134, 0.63) 96.21%, rgba(76, 127, 140, 0.6) 100%);
`;

const projectsGrad = `  
background : -moz-linear-gradient(-1.39% 99.82% 70deg,rgba(48, 80, 135, 0.6) 0%,rgba(53, 72, 132, 0.67) 6.02%,rgba(63, 56, 125, 0.86) 22.43%,rgba(67, 50, 122, 1) 35%,rgba(67, 50, 122, 0.9) 70%,rgba(67, 53, 123, 0.71) 78.27%,rgba(66, 61, 126, 0.58) 83.64%,rgba(64, 76, 132, 0.48) 88.2%,rgba(62, 97, 141, 0.38) 92.29%,rgba(59, 125, 151, 0.29) 96.08%,rgba(55, 158, 164, 0.21) 99.59%,rgba(55, 162, 166, 0.2) 100%);
background : -webkit-linear-gradient(70deg, rgba(48, 80, 135, 0.6) 0%, rgba(53, 72, 132, 0.67) 6.02%, rgba(63, 56, 125, 0.86) 22.43%, rgba(67, 50, 122, 1) 35%, rgba(67, 50, 122, 0.9) 70%, rgba(67, 53, 123, 0.71) 78.27%, rgba(66, 61, 126, 0.58) 83.64%, rgba(64, 76, 132, 0.48) 88.2%, rgba(62, 97, 141, 0.38) 92.29%, rgba(59, 125, 151, 0.29) 96.08%, rgba(55, 158, 164, 0.21) 99.59%, rgba(55, 162, 166, 0.2) 100%);
background : -webkit-gradient(linear,-1.39% 99.82% ,101.39% 0.18% ,color-stop(0,rgba(48, 80, 135, 0.6) ),color-stop(0.0602,rgba(53, 72, 132, 0.67) ),color-stop(0.2243,rgba(63, 56, 125, 0.86) ),color-stop(0.35,rgba(67, 50, 122, 1) ),color-stop(0.7,rgba(67, 50, 122, 0.9) ),color-stop(0.7827,rgba(67, 53, 123, 0.71) ),color-stop(0.8364,rgba(66, 61, 126, 0.58) ),color-stop(0.882,rgba(64, 76, 132, 0.48) ),color-stop(0.9229,rgba(62, 97, 141, 0.38) ),color-stop(0.9608,rgba(59, 125, 151, 0.29) ),color-stop(0.9959,rgba(55, 158, 164, 0.21) ),color-stop(1,rgba(55, 162, 166, 0.2) ));
background : -o-linear-gradient(70deg, rgba(48, 80, 135, 0.6) 0%, rgba(53, 72, 132, 0.67) 6.02%, rgba(63, 56, 125, 0.86) 22.43%, rgba(67, 50, 122, 1) 35%, rgba(67, 50, 122, 0.9) 70%, rgba(67, 53, 123, 0.71) 78.27%, rgba(66, 61, 126, 0.58) 83.64%, rgba(64, 76, 132, 0.48) 88.2%, rgba(62, 97, 141, 0.38) 92.29%, rgba(59, 125, 151, 0.29) 96.08%, rgba(55, 158, 164, 0.21) 99.59%, rgba(55, 162, 166, 0.2) 100%);
background : linear-gradient(20deg, rgba(48, 80, 135, 0.6) 0%, rgba(53, 72, 132, 0.67) 6.02%, rgba(63, 56, 125, 0.86) 22.43%, rgba(67, 50, 122, 1) 35%, rgba(67, 50, 122, 0.9) 70%, rgba(67, 53, 123, 0.71) 78.27%, rgba(66, 61, 126, 0.58) 83.64%, rgba(64, 76, 132, 0.48) 88.2%, rgba(62, 97, 141, 0.38) 92.29%, rgba(59, 125, 151, 0.29) 96.08%, rgba(55, 158, 164, 0.21) 99.59%, rgba(55, 162, 166, 0.2) 100%);
`;

const contactGrad = `  
background : -moz-linear-gradient(31.12% 113.97% 70deg,rgba(26, 6, 128, 0.2) 0%,rgba(41, 20, 123, 0.31) 6.94%,rgba(55, 34, 119, 0.47) 17.1%,rgba(63, 42, 116, 0.67) 29.45%,rgba(66, 44, 115, 1) 50%,rgba(62, 47, 94, 0.75) 80%,rgba(20, 14, 26, 0.2) 100%);
background : -webkit-linear-gradient(70deg, rgba(26, 6, 128, 0.2) 0%, rgba(41, 20, 123, 0.31) 6.94%, rgba(55, 34, 119, 0.47) 17.1%, rgba(63, 42, 116, 0.67) 29.45%, rgba(66, 44, 115, 1) 50%, rgba(62, 47, 94, 0.75) 80%, rgba(20, 14, 26, 0.2) 100%);
background : -webkit-gradient(linear,31.12% 113.97% ,68.88% -13.97% ,color-stop(0,rgba(26, 6, 128, 0.2) ),color-stop(0.0694,rgba(41, 20, 123, 0.31) ),color-stop(0.171,rgba(55, 34, 119, 0.47) ),color-stop(0.2945,rgba(63, 42, 116, 0.67) ),color-stop(0.5,rgba(66, 44, 115, 1) ),color-stop(0.8,rgba(62, 47, 94, 0.75) ),color-stop(1,rgba(20, 14, 26, 0.2) ));
background : -o-linear-gradient(70deg, rgba(26, 6, 128, 0.2) 0%, rgba(41, 20, 123, 0.31) 6.94%, rgba(55, 34, 119, 0.47) 17.1%, rgba(63, 42, 116, 0.67) 29.45%, rgba(66, 44, 115, 1) 50%, rgba(62, 47, 94, 0.75) 80%, rgba(20, 14, 26, 0.2) 100%);
background : -ms-linear-gradient(70deg, rgba(26, 6, 128, 0.2) 0%, rgba(41, 20, 123, 0.31) 6.94%, rgba(55, 34, 119, 0.47) 17.1%, rgba(63, 42, 116, 0.67) 29.45%, rgba(66, 44, 115, 1) 50%, rgba(62, 47, 94, 0.75) 80%, rgba(20, 14, 26, 0.2) 100%);
background : linear-gradient(20deg, rgba(26, 6, 128, 0.2) 0%, rgba(41, 20, 123, 0.31) 6.94%, rgba(55, 34, 119, 0.47) 17.1%, rgba(63, 42, 116, 0.67) 29.45%, rgba(66, 44, 115, 1) 50%, rgba(62, 47, 94, 0.75) 80%, rgba(20, 14, 26, 0.2) 100%);
`;
const gradients = {
    'header' : headerGrad,
    'skills' : skillsGrad,
    'projects' : projectsGrad,
    'contact' : contactGrad
}

export { skillsSvgData, patterns, gradients };